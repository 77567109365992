import { Box, Button, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getcategoryInfo,
  registerCategory,
  updateCategory,
} from "../../api/userapi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setCategoryInfo, setSubCategoryInfo } from "../../feature/userSlice";
import EditIcon from "@mui/icons-material/Edit";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Category = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();

  const { categoryinformation } = useSelector(
    (store) => store.userReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getcategoryAndSubInfo();
  }, []);
  const getcategoryAndSubInfo = async () => {
    var categoryinfo = await getcategoryInfo();
    dispatch(setCategoryInfo(categoryinfo.Message));
  };

  const tableClass =
    theme.palette.mode === "dark"
      ? "table table-striped table-dark"
      : "table table-striped";
  const emptyColor = theme.palette.mode === "dark" ? "black" : "white";
  const showToastMessage = (type, message) => {
    toast(message, {
      type: type,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const [categoryName, setCategoryName] = useState("");

  const handleFormSubmitCategory = async (event) => {
    event.preventDefault();
    var post = new FormData();
    if (upCategoryID === "") {
      post.append("action", "registerCategory");
      post.append("CategoryName", categoryName);
      var Categoryinfo = await registerCategory(post);
      // console.log(Categoryinfo);
      if (Categoryinfo.status) {
        showToastMessage("success", Categoryinfo.Message);
        setCategoryName("");
        getcategoryAndSubInfo();
      } else {
        showToastMessage("error", Categoryinfo.Message);
      }
    } else {
      if (CategoryStatus === "") {
        alert("Select Status");
        return;
      }
      post = new FormData();
      post.append("action", "updateCategory");
      post.append("CategoryID", upCategoryID);
      post.append("CategoryName", categoryName);
      post.append("CategoryStatus", CategoryStatus);
      Categoryinfo = await updateCategory(post);
      // console.log(Categoryinfo);
      if (Categoryinfo.status) {
        showToastMessage("success", Categoryinfo.Message);
        document.getElementById("btnCategory").innerHTML = "Register";
        setCategoryName("");
        setUpCategoryID("");
        getcategoryAndSubInfo();
      } else {
        showToastMessage("error", Categoryinfo.Message);
      }
    }
  };
  const [upCategoryID, setUpCategoryID] = useState("");
  const [CategoryStatus, setUpCategoryStatus] = useState("");
  const handleClickEditCat = (currentCategoryId) => {
    document.getElementById("btnCategory").innerHTML = "Update";
    setCategoryName(categoryinformation[currentCategoryId]["category_name"]);
    setUpCategoryID(categoryinformation[currentCategoryId]["category_id"]);
  };
  const selectBgColor = theme.palette.mode === "light" ? "#E8F0FE" : "#323848";
  const selectFgColor = theme.palette.mode === "light" ? "black" : "white";

  return (
    <Box m="20px">
      <ToastContainer />
      <Header
        title="REGISTER/VIEW CATEGORY"
        subtitle="Create/View a New Category"
      />          
          <form id="regCategoryForm" onSubmit={handleFormSubmitCategory}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Category Name"
                value={categoryName}
                onChange={(event) => setCategoryName(event.target.value)}
                name="SupscriptionName"
                sx={{ gridColumn: "span 2" }}
                required
              />
              {upCategoryID && (
                <select
                  // fullWidth
                  variant="filled"
                  component="select"
                  onChange={(event) => setUpCategoryStatus(event.target.value)}
                  value={CategoryStatus}
                  style={{
                    gridColumn: "span 1",
                    backgroundColor: selectBgColor,
                    color: selectFgColor,
                  }}
                  required
                >
                  <option value="">Select Status</option>
                  <option value="Active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              )}
              <Button
                type="submit"
                id="btnCategory"
                color="secondary"
                variant="contained"
              >
                Register
              </Button>
            </Box>
          </form>
          {categoryinformation.length === 0 ? (
            <h1
              style={{
                color: { emptyColor },
                textAlign: "center",
                marginTop: "8%",
              }}
            >
              No data Found
            </h1>
          ) : (
            <Table className={tableClass} style={{ marginTop: "3%",width:"75%" }}>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  {/* <th scope="col">ID</th> */}
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {categoryinformation.map((category, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {/* <td>{category.category_id}</td> */}
                    <td>{category.category_name}</td>
                    <td>{category.category_status}</td>

                    <td>
                      <Button
                        style={{ backgroundColor: "#0B5ED7" }}
                        variant="contained"
                        onClick={() => handleClickEditCat(index)}
                      >
                        <EditIcon style={{ color: "white" }} />
                      </Button>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
    </Box>
  );
};

export default Category;
