import { Box, Button, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import {
  getCusTodayPayInfo,
  getCustomersInfo,
  getSupplierInfo,
  paymentReport,
} from "../../api/financeapi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { Table, Modal } from "react-bootstrap";
// import { AiFillCloseCircle } from "react-icons/ai";
import B from "react-bootstrap/Button";
import Pagination from "../../components/Pagination";
import Select from "react-select";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const showToastMessage = (type, message) => {
    toast(message, {
      type: type,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const [salesreportinformation, setSalesreportInformation] = useState([]);
  const [istoday, setisToday] = useState(true);
  const [salesinfo, setSalesInfo] = useState({
    customer_id: "",
    fromDate: "",
    toDate: "",
  });
  const [customer_information, setCustomerInfo] = useState([]);
  const [customerData, setcustomerData] = useState([]);
  const [customerVal, setcustomerVal] = useState("");
  const [supplier_information, setSupplierInfo] = useState([]);
  const [isCustomer, setIsCustomer] = useState(true);
  const [typeReport, setTypeReport] = useState(true);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().split("T")[0];
  useEffect(() => {
    getcustomerinfo();
    handleTodayPayment("N");
  }, []);
  const getcustomerinfo = async () => {
    var customerInfo = await getCustomersInfo();
    var supplierinfo = await getSupplierInfo();
    setCustomerInfo(customerInfo.Message);
    setSupplierInfo(supplierinfo.Message);

     var filter_customer = [];
        for (var i = 0; i < customerInfo.Message.length; i++) {
            filter_customer.push({
              value: customerInfo.Message[i].customer_id,
              label:
                customerInfo.Message[i].customer_name +
                " | " +
                customerInfo.Message[i].customer_phone,
            });
        }
        console.log(filter_customer);
        setcustomerData(filter_customer)
  };
  const handleFormSubmit = async (event) => {
    setisToday(false);
    document.getElementById("btnSubmit").innerText = "Loading...";

    setSalesreportInformation([]);
    event.preventDefault();
    var post = new FormData();
    post.append("action", "paymentReport");
    // post.append("CustomerID", salesinfo.customer_id);
    post.append("CustomerID", customerVal.value == undefined ? "" : customerVal.value);
    post.append("SupplierID", salesinfo.supplier_id);
    post.append("FromDate", salesinfo.fromDate);
    post.append("ToDate", salesinfo.toDate);
    if (typeReport === false) {
      post.append("Type", "General");
    } else {
      post.append("Type", "Date");
    }
    if (isCustomer === false) {
      post.append("PRType", "Su");
    } else {
      post.append("PRType", "Cu");
    }
    if(isCustomer === true && (typeReport === false || typeReport === true)) {
      setisToday(true);
    }
    var salesreportInfo = await paymentReport(post);

    if (salesreportInfo.status) {
      if (salesreportInfo.Message.length === 0)
        showToastMessage("error", "No data Found.");

      setSalesreportInformation(salesreportInfo.Message);
      var regExpenseForm = document.getElementById("regExpenseForm");
      regExpenseForm.reset();
      setSalesInfo({
        customer_id: "",
        fromDate: "",
        toDate: "",
      });
      setTypeReport(true);
    } else {
      setSalesreportInformation([]);

      showToastMessage("error", salesreportInfo.Message);
    }
    document.getElementById("btnSubmit").innerText = "Search";
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSalesInfo({ ...salesinfo, [name]: value });

    if (name === "report_type") {
      if (value === "General") {
        setTypeReport(false);
      } else {
        setTypeReport(true);
      }
    }
    if (name === "payment_type") {
      if (value === "Su") {
        setIsCustomer(false);
      } else {
        setIsCustomer(true);
      }
    }
  };

  const handleTodayPayment = async (type) => {
    setSalesreportInformation([]);
    setisToday(true);
    var cusTodayPayment = await getCusTodayPayInfo();
    console.log(type);
    if (cusTodayPayment.status) {
      if(type !== "N")
        if (cusTodayPayment.Message.length === 0)
          showToastMessage("error", "No data Found.");
      setSalesreportInformation(cusTodayPayment.Message);
    } else {
      setSalesreportInformation([]);
      showToastMessage("error", cusTodayPayment.Message);
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(15);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = salesreportinformation.slice(
    firstPostIndex,
    lastPostIndex
  );
  // end pagination

  const tableClass =
    theme.palette.mode === "dark"
      ? "table table-striped table-dark"
      : "table table-striped";
  const emptyColor = theme.palette.mode === "dark" ? "black" : "white";
  const selectBgColor = theme.palette.mode === "light" ? "#E8F0FE" : "#323848";
  const selectFgColor = theme.palette.mode === "light" ? "black" : "white";
  var totalpaid = 0;

  return (
    <Box m="20px">
      <ToastContainer />
      <Header title="PAYMENT REPORT" subtitle="" />
      <Box
        style={{
          justifyContent: "flex-start",
          display: "flex",
          marginBottom: "1%",
        }}
      >
        <B onClick={()=>handleTodayPayment("Y")}>
          {/* <DownloadOutlinedIcon sx={{ mr: "10px" }} /> */}
          Today
        </B>
      </Box>
      <form id="regExpenseForm" onSubmit={handleFormSubmit}>
        <Box
          display="grid"
          gap="40px"
          gridTemplateColumns="repeat(5, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <div className="row" style={{ width: "600%" }}>
            <div className="col-1">
              <select
                onChange={handleOnChange}
                fullWidth
                variant="filled"
                component="select"
                name="payment_type"
                id="payment_type"
                style={{
                  backgroundColor: selectBgColor,
                  color: selectFgColor,
                  height: "100%",
                  width: "100%",
                }}
                required
              >
                <option value="">Select</option>
                <option value="Cu">Customer</option>
                <option value="Su">Supplier</option>
              </select>
            </div>
            <div className="col-2">
              <select
                onChange={handleOnChange}
                fullWidth
                variant="filled"
                component="select"
                name="supplier_id"
                id="supplier_id"
                style={{
                  backgroundColor: selectBgColor,
                  color: selectFgColor,
                  height: "100%",
                  width: "100%",
                }}
                disabled={isCustomer}
              >
                <option value="">Select Supplier</option>
                {supplier_information.map((supplier, index) => (
                  <option key={index} value={supplier.supplier_id}>
                    {supplier.supplier_name + " | " + supplier.supplier_phone}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-2">
              {/* <select
                onChange={handleOnChange}
                fullWidth
                variant="filled"
                component="select"
                name="customer_id"
                id="customer_id"
                style={{
                  backgroundColor: selectBgColor,
                  color: selectFgColor,
                  height: "100%",
                  width: "100%",
                }}
                disabled={!isCustomer}
              >
                <option value="">Select Customer</option>
                {customer_information.map((customer, index) => (
                  <option key={index} value={customer.customer_id}>
                    {customer.customer_name + " | " + customer.customer_phone}
                  </option>
                ))}
              </select> */}
              <Select
                  value={customerVal}
                  styles={{
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      // color: "black",
                      backgroundColor: selectBgColor,
                      color: selectFgColor,
                      height: "100%",
                      width: "100%",
                    }),
                  }}
                  name="customer_id"
                  id="customer_id"
                  options={customerData}
                  onChange={setcustomerVal}
                  disabled={!isCustomer}
                  // required
                />
            </div>
            <div className="col-2">
              <select
                onChange={handleOnChange}
                fullWidth
                variant="filled"
                component="select"
                name="report_type"
                id="report_type"
                style={{
                  backgroundColor: selectBgColor,
                  color: selectFgColor,
                  height: "100%",
                  width: "100%",
                }}
                required
              >
                <option value="">Select Type</option>
                <option value="General">General</option>
                <option value="Date">By Date</option>
              </select>
            </div>

            <div className="col-2">
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="From Date"
                onChange={handleOnChange}
                // value={values.RegisterDate}
                inputProps={{
                  max: tomorrowFormatted,
                }}
                name="fromDate"
                // sx={{ gridColumn: "span 2" }}
                required={typeReport}
                disabled={!typeReport}
              />
            </div>
            <div className="col-2">
              <TextField
                fullWidth
                variant="filled"
                type="date"
                inputProps={{
                  max: tomorrowFormatted,
                }}
                label="To Date"
                onChange={handleOnChange}
                // value={values.RegisterDate}
                name="toDate"
                // sx={{ gridColumn: "span 2" }}
                required={typeReport}
                disabled={!typeReport}
              />
            </div>

            <div className="col-1">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                style={{ height: "100%", width: "100%" }}
                id="btnSubmit"
              >
                Search
              </Button>
            </div>
          </div>
        </Box>
      </form>

      {salesreportinformation.length === 0 ? (
        <h1
          style={{
            color: { emptyColor },
            textAlign: "center",
            marginTop: "8%",
          }}
        >
          No data Found
        </h1>
      ) : (
        <>
          <Table className={tableClass} style={{ marginTop: "3%" }}>
            <thead>
              <tr>
                <th scope="col">#</th>
                {istoday && (
                  <>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Customer Phone</th>
                  </>
                )}
                <th scope="col">Paid</th>
                <th scope="col">Bank</th>
                <th scope="col">User</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts.map((sale, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {istoday && (
                      <>
                        <td>{sale.customer_name}</td>
                        <td>{sale.customer_phone}</td>
                      </>
                    )}
                    <td>${parseFloat(sale.Paid).toLocaleString("en-US")}</td>
                    <td>{sale.bank_name}</td>
                    <td>{sale.user_fullname}</td>
                    <td>{sale.date}</td>
                  </tr>
                );
              })}
               {salesreportinformation.map((sale, index) => {
                totalpaid += parseFloat(sale.Paid)
              })}
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>${totalpaid.toLocaleString("en-US")}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              
              </tr> 
            </tbody>
          </Table>
          <Pagination
            totalPosts={salesreportinformation.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </>
      )}
    </Box>
  );
};

export default Form;
