import { Box, useTheme, IconButton } from "@mui/material";
import { BsFillCartCheckFill, BsFillPlusCircleFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
// import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { Table, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getProductInfo } from "../../api/productapi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleModal,
  // setCurrentproductInfo,
  setCartProductsInfo,
  setCartProducts,
  setproductInfo,
} from "../../feature/productSlice";
import Modal from "./SellModal";

import Pagination from "../../components/Pagination";

const SellProduct = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { productinformation, CartProducts } = useSelector(
    (store) => store.productReducer
  );
  const productinformation_withNozericurrentproduct = productinformation.filter(
    (pro) => pro.current_quantity > 0
  );
  const [filtered_productInformation, setfiltered_productInformation] =
    useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getproductInfo();
  }, []);
  const getproductInfo = async () => {
    var productInfo = await getProductInfo();
    dispatch(setproductInfo(productInfo.Message));
    const Nozericurrentproduct = productInfo.Message.filter(
      (pro) => pro.current_quantity > 0
    );
    setfiltered_productInformation(Nozericurrentproduct);
    dispatch(setCartProducts([]));
  };

  //   const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleClick = (currentProductID) => {
    var productinformation_filter_edit = productinformation.filter(
      (pro) => pro.product_id === currentProductID
    );
    // dispatch(setCurrentproductInfo(productinformation_filter_edit[0]));
    dispatch(setCartProductsInfo(productinformation_filter_edit[0]));
    // dispatch(handleModal());
  };
  const handleRemove = (currentProductID) => {
    var cartproductinformation = CartProducts.filter(
      (cart) => cart.product_id !== currentProductID
    );
    dispatch(setCartProducts(cartproductinformation));
  };

  const handleClickCartPage = () => {
    if (CartProducts.length > 0) {
      dispatch(handleModal());
    } else {
      showToastMessage("error", "Fadlan dooro product");
    }
  };

  const handleSerach = (event) => {
    event.preventDefault();
    var PATTERN = event.target.value.toLowerCase();
    setfiltered_productInformation(
      productinformation_withNozericurrentproduct.filter(function (str) {
        return (
          str.product_name.toLowerCase().indexOf(PATTERN) !== -1 ||
          str.product_id.toLowerCase().indexOf(PATTERN) !== -1
        );
      })
    );
  };
  const showToastMessage = (type, message) => {
    toast(message, {
      type: type,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(25);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filtered_productInformation.slice(
    firstPostIndex,
    lastPostIndex
  );
  // end pagination
  const tableClass =
    theme.palette.mode === "dark"
      ? "table table-striped table-dark"
      : "table table-striped";
  const emptyColor = theme.palette.mode === "dark" ? "black" : "white";
  if (productinformation.length === 0)
    return (
      <h1 style={{ color: { emptyColor }, textAlign: "center" }}>
        No data Found
      </h1>
    );
  return (
    <Box m="20px">
      <ToastContainer />
      <Header
        title="SELL PRODUCT INFORMATION"
        subtitle="View a Sell Product Information"
      />
      <Modal
        showToastMessage={showToastMessage}
        getproductInfo={getproductInfo}
      />
      {/* SEARCH BAR */}
      <Box display="flex" justifyContent="end" style={{ marginBottom: "3%" }}>
        <Box
          display="flex"
          borderRadius="3px"
          style={{ width: "4%", marginRight: "2%", backgroundColor: "#EF2D25" }}
        >
          <Button
            className="btn btn-md"
            onClick={() => handleClickCartPage()}
            style={{ backgroundColor: "#EF2D25" }}
          >
            <BsFillCartCheckFill />
          </Button>
        </Box>
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
          style={{ width: "30%" }}
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            onChange={handleSerach}
            placeholder="Search"
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      {filtered_productInformation.length > 0 ? (
        <>
          <Table className={tableClass}>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Category</th>
                <th scope="col">Quantity</th>
                <th scope="col">Current</th>
                <th scope="col">Buying</th>
                <th scope="col">Selling</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts.map((product, index) => {
                var tablebody = "";
                const isInCart = CartProducts.some(
                  (cart) => cart.product_id === product.product_id
                );

                if (isInCart === false) {
                  tablebody = (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{product.product_id}</td>
                      <td>{product.product_name}</td>
                      <td>{product.category_name}</td>
                      <td>{product.product_quantity}</td>
                      <td>{product.current_quantity}</td>
                      <td>${product.product_buying_price}</td>
                      <td>${product.product_selling_price}</td>

                      <td>
                        <Button
                          className="btn btn-sm"
                          onClick={() => handleClick(product.product_id)}
                        >
                          <BsFillPlusCircleFill />
                        </Button>{" "}
                      </td>
                    </tr>
                  );
                } else {
                  tablebody = (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{product.product_id}</td>
                      <td>{product.product_name}</td>
                      <td>{product.category_name}</td>
                      <td>{product.product_quantity}</td>
                      <td>{product.current_quantity}</td>
                      <td>${product.product_buying_price}</td>
                      <td>${product.product_selling_price}</td>

                      <td>
                        <Button
                          className="btn btn-sm bg-danger"
                          onClick={() => handleRemove(product.product_id)}
                        >
                          <AiFillCloseCircle />
                        </Button>
                      </td>
                    </tr>
                  );
                }
                return tablebody;
              })}
            </tbody>
          </Table>
          <Pagination
            totalPosts={filtered_productInformation.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </>
      ) : (
        <h1 style={{ color: { emptyColor }, textAlign: "center" }}>
          No Product Found
        </h1>
      )}
    </Box>
  );
};

export default SellProduct;
