import { Box, TableBody, TableRow, useTheme, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { Table, Button } from "react-bootstrap";
import { Modal as ModalAdj } from "react-bootstrap";
import { Modal as ModalUpd } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  adjusmentProduct,
  getProductInfo,
  updateProduct,
} from "../../api/productapi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleModal,
  setCurrentproductInfo,
  setproductInfo,
} from "../../feature/productSlice";
import Modal from "./Modal";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Pagination from "../../components/Pagination";
import { FaStoreAlt, FaStoreAltSlash } from "react-icons/fa";
import myImage from "../system_logo.jpg";
import { tokens } from "../../theme";
import { setBankAccountInfo, setCategoryInfo } from "../../feature/userSlice";
import { getcategoryInfo } from "../../api/userapi";
import { getBankAccount, getSupplierInfo } from "../../api/financeapi";

const ViewProduct = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { productinformation } = useSelector((store) => store.productReducer);
  const { bankaccountinformation, categoryinformation } = useSelector(
    (store) => store.userReducer
  );
  const [showU, setShowU] = useState(false);
  const [show, setShow] = useState(false);
  const [filter_productinformation, setFilterProduct] = useState([]);
  const [currentAdjPro, setCurretAdj] = useState([]);
  const [isIncrease, setIncrease] = useState(false);
  const [isCash, setIsCash] = useState(true);
  const [supplier_information, setSupplierInfo] = useState([]);

  const active_categoryinformation = categoryinformation.filter(
    (cat) => cat.category_status === "Active"
  );

  const dispatch = useDispatch();
  useEffect(() => {
    getproductInfo();
  }, []);
  const getproductInfo = async () => {
    var bankaccountinfo = await getBankAccount();
    var productInfo = await getProductInfo();
    var categoryinfo = await getcategoryInfo();
    var supplierinfo = await getSupplierInfo();
    dispatch(setBankAccountInfo(bankaccountinfo.Message));
    const productinformation_withNozericurrentproduct =
      productInfo.Message.filter((pro) => pro.current_quantity > 0);
    setFilterProduct(productinformation_withNozericurrentproduct);
    dispatch(setproductInfo(productInfo.Message));
    dispatch(setCategoryInfo(categoryinfo.Message));
    setSupplierInfo(supplierinfo.Message);
  };

  const handleAll = () => {
    var productinformation_withNozericurrentproduct = productinformation.filter(
      (pro) => pro.current_quantity > 0
    );
    setFilterProduct(productinformation_withNozericurrentproduct);
  };
  const handleAllZero = () => {
    var productinformation_withzericurrentproduct = productinformation.filter(
      (pro) => pro.current_quantity == 0
    );
    setFilterProduct(productinformation_withzericurrentproduct);
  };
  //   const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleClick = (currentProductID) => {
    // if(productinformation[currentCompanyID].product_quantity !== productinformation[currentCompanyID].current_quantity){
    //   showToastMessage("error", "This product cannot be updated because some of them have been sold")
    //   return;
    // }
    var productinformation_filter_edit = productinformation.filter(
      (pro) => pro.product_id === currentProductID
    );
    // console.log(currentCompanyID);
    dispatch(setCurrentproductInfo(productinformation_filter_edit[0]));
    dispatch(handleModal());
  };
  const showToastMessage = (type, message) => {
    toast(message, {
      type: type,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const columns = [
    { title: "Code", field: "product_id" },
    { title: "Name", field: "product_name" },
    { title: "Category", field: "category_name" },
    { title: "Type", field: "product_buying_type" },
    { title: "Quantity", field: "product_quantity" },
    { title: "Current", field: "current_quantity" },
    { title: "Buying", field: "product_buying_price" },
    { title: "Selling", field: "product_selling_price" },
  ];
  const downloadPdf = () => {
    var tableData = filter_productinformation;
    var quantity = 0;
    var currentquantity = 0;
    var buyingprice = 0;
    var sellingprice = 0;

    filter_productinformation.map((product, index) => {
      quantity += parseFloat(product.product_quantity);
      currentquantity += parseFloat(product.current_quantity);
      buyingprice += parseFloat(product.product_buying_price);
      sellingprice += parseFloat(product.product_selling_price);
    });
    tableData.push({
      pproduct_id: "-",
      product_name: "-",
      category_name: "-",
      product_quantity: quantity,
      current_quantity: currentquantity,
      product_buying_price: buyingprice,
      product_selling_price: sellingprice,
    });
    var doc = new jsPDF();
    const imgData = myImage;

    // Add the image to the PDF
    doc.addImage(imgData, "JPEG", 80, 0, 50, 50); // Adjust the coordinates and dimensions as needed
    doc.text(process.env.REACT_APP_COMPANY_NAME, 70, 45);
    doc.text("PRODUCT INFORMATION", 70, 55);
    const tableOptions = {
      startX: 20,
      startY: 65,
      margin: { top: 20, bottom: 20 },
      styles: { overflow: "linebreak" },
    };
    doc.autoTable({
      theme: "grid",
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: tableData,
      ...tableOptions,
    });
    var currentdate = new Date();
    var documentName =
      "Product Data " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " : " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds() +
      ".pdf";
    // console.log(documentName);
    doc.save(documentName);
  };
  const handleSerach = (event) => {
    event.preventDefault();
    var PATTERN = event.target.value.toLowerCase();
    if (PATTERN == "") {
      const productinformation_withNozericurrentproduct =
        productinformation.filter((pro) => pro.current_quantity > 0);
      setFilterProduct(productinformation_withNozericurrentproduct);
    } else
      setFilterProduct(
        filter_productinformation.filter(function (str) {
          return (
            str.product_name.toLowerCase().indexOf(PATTERN) !== -1 ||
            str.product_id.toLowerCase().indexOf(PATTERN) !== -1 ||
            str.product_buying_type.toLowerCase().indexOf(PATTERN) !== -1
          );
        })
      );
  };
  const handleClose = () => {
    setShow(false);
    setIncrease(false);
    setCurretAdj([])
    setIsCash(true)
  };
  const handleCloseU = () => {
    setShowU(false);
    setCurretAdj([])
  };
  const handleClickUpd = (curentProductID) => {
    var currproduct = productinformation.filter(
      (pro) => pro.product_id === curentProductID
    );
    setCurretAdj(currproduct[0]);
    setShowU(true);
  };
  const handleOnChangeU = (event) => {
    const { name, value } = event.target;
    setCurretAdj({ ...currentAdjPro, [name]: value });
  };

  const handleClickYesU = async (event) => {
    event.preventDefault();
    var post = new FormData();
    post.append("action", "updateProduct");
    post.append("ProductID", currentAdjPro.product_id);
    post.append("ProductName", currentAdjPro.product_name);
    post.append("CategoryID", currentAdjPro.category_id);
    post.append("SellingPrice", currentAdjPro.product_selling_price);
    post.append("Up", "1");
    var productinfo = await updateProduct(post);
    // console.log(productinfo);
    // return;
    if (productinfo.status) {
      showToastMessage("success", productinfo.Message);
      getproductInfo();
      setShowU(false);
    } else {
      showToastMessage("error", productinfo.Message);
    }
  };

  const handleClickAdj = (curentProductID) => {
    var currproduct = productinformation.filter(
      (pro) => pro.product_id === curentProductID
    );
    setCurretAdj(currproduct[0]);
    setShow(true);
  };
  const handleOnChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === "AdjusmentType") {
      if (value == "In") setIncrease(true);
      else setIncrease(false);
    }
    if (name === "buy_type") {
      if (value == "Cash") setIsCash(true);
      else setIsCash(false);
    }
    if (isIncrease) {
      if (name === "buyprice") {
        if (value == "") {
          showToastMessage("error", "Fadlan soo gali tiro");
          document.getElementById("quantity").value = "";
          return;
        }
      }
    }
    if (name === "quantity") {
      if (value == "") {
        showToastMessage("error", "Fadlan soo gali tiro");
        document.getElementById("quantity").value = "";
        return;
      }
      // if (value % 1 !== 0) {
      //   showToastMessage("error", "Fadlan soo gali Number Aan jajab ahayn");
      //   document.getElementById("quantity").value = "";
      //   return;
      // }
      if (!isIncrease) {
        if (parseFloat(value) > parseFloat(currentAdjPro.current_quantity)) {
          showToastMessage(
            "error",
            "Fadlan soo gali tiro ka yar inta hadda taalo"
          );
          document.getElementById("quantity").value = "";
          return;
        }
      } else {
      }
    }
  };

  const handleClickYes = async (event) => {
    event.preventDefault();
    var quantity = document.getElementById("quantity").value;
    var adjType = document.getElementById("AdjusmentType").value;
    var bankid = "-";
    var supplier_id = "-";
    var adjbuytype = "-";
    var buyprice = parseFloat(currentAdjPro.product_buying_price);
    var adjamount = 0;

    if (adjType == "") {
      showToastMessage("error", "Fadlan dooro Adjustment");
      return;
    }
    if (quantity == "") {
      showToastMessage("error", "Fadlan soo gali tiro");
      return;
    }

    if (isIncrease) {
      if(isCash){
        adjbuytype = "Cash"
        bankid = document.getElementById("bank_accounts").value;
        if (bankid == "") {
          showToastMessage("error", "Fadlan dooro Account");
          return;
        }
      }else{
        adjbuytype = "Liability"
        supplier_id = document.getElementById("supplier_id").value;
         if (supplier_id == "") {
          showToastMessage("error", "Fadlan dooro Supplier");
          return;
        }
      }
      buyprice = document.getElementById("buyprice").value;
      if (buyprice == "") {
        showToastMessage("error", "Fadlan soo gali Lacag");
        return;
      }

      adjamount = parseFloat(buyprice) * parseFloat(quantity);
    } else {
      if (parseFloat(quantity) > parseFloat(currentAdjPro.current_quantity)) {
        showToastMessage(
          "error",
          "Fadlan soo gali tiro ka yar inta hadda taalo"
        );
        document.getElementById("quantity").value = "";
        return;
      }
      adjamount =
        parseFloat(currentAdjPro.product_buying_price) * parseFloat(quantity);
    }

    var post = new FormData();
    post.append("action", "adjusmentProduct");
    post.append("ProductID", currentAdjPro.product_id);
    post.append("ProductQuantity", quantity);
    post.append("BuyingPrice", buyprice);
    post.append("Amount", adjamount);
    post.append("Type", adjType);
    post.append("BuyType", currentAdjPro.product_buying_type);
    post.append("AdjBuyType", adjbuytype);
     post.append("SupplierID", supplier_id);
    post.append("BankID", bankid);
    post.append("UpDate", currentAdjPro.product_reg_date);
    var productinfo = await adjusmentProduct(post);
    // console.log(productinfo);
    // return;
    if (productinfo.status) {
      showToastMessage("success", productinfo.Message);
      getproductInfo();
      setShow(false);
    } else {
      showToastMessage("error", productinfo.Message);
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(25);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filter_productinformation.slice(
    firstPostIndex,
    lastPostIndex
  );
  // end pagination
  const tableClass =
    theme.palette.mode === "dark"
      ? "table table-striped table-dark"
      : "table table-striped";
  const emptyColor = theme.palette.mode === "dark" ? "black" : "white";
  if (productinformation.length === 0)
    return (
      <h1 style={{ color: { emptyColor }, textAlign: "center" }}>
        No data Found
      </h1>
    );
  var quantity = 0;
  var currentquantity = 0;
  var buyingprice = 0;
  var sellingprice = 0;
  return (
    <Box m="20px">
      <ToastContainer />
      <ModalAdj show={show} onHide={handleClose} style={{ color: "black" }}>
        <ModalAdj.Header closeButton>
          <ModalAdj.Title>Adjusment this Product?</ModalAdj.Title>
        </ModalAdj.Header>
        <ModalAdj.Body>
          <div className="row">
            <div className="form-group col-md-6">
              <select
                onChange={handleOnChange}
                className="form-select"
                name="AdjusmentType"
                id="AdjusmentType"
              >
                <option value="">Select Adjusment</option>
                <option value="In">Increase</option>
                <option value="De">Decrease</option>
              </select>
            </div>
            {isIncrease && (
              <>
                <div className="form-group col-md-6">
                  <select
                    className="form-select"
                    name="buy_type"
                    id="buy_type"
                    // value={currentProduct.category_id}
                    onChange={handleOnChange}
                    required
                  >
                    <option value="Cash">Cash</option>
                    <option value="Liability">Liability/Deyn</option>
                  </select>
                </div>
                {isCash === true ? (
                  <div className="form-group col-md-5 mt-2">
                    <select
                      className="form-select"
                      name="bank_accounts"
                      id="bank_accounts"
                      // value={currentProduct.category_id}
                      onChange={handleOnChange}
                      required
                    >
                      <option value="">Select Account</option>
                      {bankaccountinformation.map((account, index) => (
                        <option key={index} value={account.bank_id}>
                          {account.bank_name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className="form-group col-md-5 mt-2">
                    <select
                     className="form-select"
                      name="supplier_id"
                      id="supplier_id"
                      // value={currentProduct.category_id}
                      onChange={handleOnChange}
                      required
                    >
                      <option value="">Select Supplier</option>
                      {supplier_information.map((supplier, index) => (
                        <option key={index} value={supplier.supplier_id}>
                          {supplier.supplier_name +
                            " | " +
                            supplier.supplier_phone}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="form-group col-md-4 mt-2">
                  <input
                    type="number"
                    min="0.0"
                    className="form-control"
                    placeholder="Buying Price"
                    name="buyprice"
                    id="buyprice"
                    onChange={handleOnChange}
                  />
                </div>
              </>
            )}
            <div
              className={
                isIncrease == true
                  ? "form-group col-md-3 mt-2"
                  : "form-group col-md-6"
              }
            >
              <input
                type="number"
                min="0.0"
                className="form-control"
                placeholder="Quantity"
                name="quantity"
                id="quantity"
                onChange={handleOnChange}
              />
            </div>
          </div>
        </ModalAdj.Body>
        <ModalAdj.Footer>
          <Button variant="primary" onClick={handleClickYes}>
            Yes
          </Button>
          <Button variant="danger" onClick={handleClose}>
            No
          </Button>
        </ModalAdj.Footer>
      </ModalAdj>

      <ModalUpd show={showU} onHide={handleCloseU} style={{ color: "black" }}>
        <ModalUpd.Header closeButton>
          <ModalUpd.Title>Update this Product?</ModalUpd.Title>
        </ModalUpd.Header>
        <ModalUpd.Body>
          <div className="row">
            <div className="form-group col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                name="product_name"
                id="product_name"
                value={currentAdjPro.product_name}
                onChange={handleOnChangeU}
              />
            </div>

            <div className="form-group col-md-4">
              <select
                className="form-select"
                name="category_id"
                id="category_id"
                value={currentAdjPro.category_id}
                onChange={handleOnChangeU}
                required
              >
                <option value="">Select Category</option>
                {active_categoryinformation.map((category, index) => (
                  <option key={index} value={category.category_id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group col-md-4">
              <input
                type="number"
                min="1"
                className="form-control"
                placeholder="Selling Price"
                name="product_selling_price"
                id="product_selling_price"
                value={currentAdjPro.product_selling_price}
                onChange={handleOnChangeU}
              />
            </div>
          </div>
        </ModalUpd.Body>
        <ModalUpd.Footer>
          <Button variant="primary" onClick={handleClickYesU}>
            Yes
          </Button>
          <Button variant="danger" onClick={handleCloseU}>
            No
          </Button>
        </ModalUpd.Footer>
      </ModalUpd>
      <Box display="flex">
        <Header
          title="VIEW PRODUCT INFORMATION"
          subtitle="View a Product Information"
          style={{ width: "50%" }}
        />
        <Box
          style={{
            width: "59%",
            display: "flex",
            marginTop: "3%",
            justifyContent: "end",
          }}
        >
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
            style={{ width: "40%", height: "65%", marginRight: "5%" }}
          >
            <InputBase
              sx={{ ml: 2, flex: 1 }}
              onChange={handleSerach}
              placeholder="Search"
            />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
          </Box>
          <Box style={{ marginRight: "6%" }}>
            <Button
              sx={{
                backgroundColor: "#141B2D",
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
                // padding: "10px 20px",
              }}
              onClick={handleAllZero}
            >
              <FaStoreAltSlash sx={{ mr: "10px" }} />
            </Button>
          </Box>
          <Box style={{ marginRight: "6%" }}>
            <Button
              sx={{
                backgroundColor: "#141B2D",
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
                // padding: "10px 20px",
              }}
              onClick={handleAll}
            >
              <FaStoreAlt sx={{ mr: "10px" }} />
            </Button>
          </Box>
          <Box>
            <Button
              sx={{
                backgroundColor: "#141B2D",
                color: "white",
                fontSize: "14px",
                fontWeight: "bold",
                // padding: "10px 20px",
              }}
              onClick={downloadPdf}
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Download
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal
        showToastMessage={showToastMessage}
        getproductInfo={getproductInfo}
      />
      <Table className={tableClass}>
        <thead>
          <tr>
            {/* <th scope="col">#</th> */}
            <th scope="col">Code</th>
            <th scope="col">Name</th>
            <th scope="col">Category</th>
            <th scope="col">Type</th>
            <th scope="col">Quantity</th>
            <th scope="col">Current</th>
            <th scope="col">Buying</th>
            <th scope="col">Selling</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <TableBody>
          {currentPosts.map((product, index) => (
            <TableRow key={index}>
              {/* <td>{index + 1}</td> */}
              <td>{product.product_id}</td>
              <td>{product.product_name}</td>
              <td>{product.category_name}</td>
              <td>{product.product_buying_type}</td>
              <td>{product.product_quantity}</td>
              <td>{product.current_quantity}</td>
              <td>${product.product_buying_price}</td>
              <td>${product.product_selling_price}</td>
              <td>
                {parseFloat(product.current_quantity) > 0 &&
                parseFloat(product.current_quantity) ===
                  parseFloat(product.product_quantity) ? (
                  <Button
                    className="btn btn-sm"
                    onClick={() => handleClick(product.product_id)}
                  >
                    <EditIcon />
                  </Button>
                ) : (
                  <Button
                    className="btn btn-sm"
                    onClick={() => handleClickUpd(product.product_id)}
                  >
                    <EditIcon />
                  </Button>
                )}

                <Button
                  type="button"
                  className="btn btn-success btn-sm"
                  variant="contained"
                  onClick={() => handleClickAdj(product.product_id)}
                  style={{ marginLeft: "10%" }}
                >
                  <TextDecreaseIcon style={{ color: "white" }} />
                </Button>
              </td>
            </TableRow>
          ))}
          {filter_productinformation.map((product, index) => {
            quantity += parseFloat(product.product_quantity);
            currentquantity += parseFloat(product.current_quantity);
            buyingprice += parseFloat(product.product_buying_price);
            sellingprice += parseFloat(product.product_selling_price);
          })}
          <TableRow>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>{quantity}</td>
            <td>{currentquantity}</td>
            <td>${buyingprice}</td>
            <td>${sellingprice}</td>
            <td>-</td>
          </TableRow>
        </TableBody>
      </Table>
      <Pagination
        totalPosts={filter_productinformation.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </Box>
  );
};

export default ViewProduct;
