import { Box, Button, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { salesReport } from "../../api/financeapi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "../../components/Pagination";
import B from "react-bootstrap/Button";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import myImage from "../system_logo.jpg";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { getUserInfo } from "../../api/userapi";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const showToastMessage = (type, message) => {
    toast(message, {
      type: type,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const [userinfo_withnodevandadmin, setuserwithno] = useState([]);
  const [salesreportinformation, setSalesreportInformation] = useState([]);
  const [salesinfo, setSalesInfo] = useState({
    user_type: "",
    fromDate: "",
    toDate: "",
  });
  const [typeReport, setTypeReport] = useState(true);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().split("T")[0];

  useEffect(() => {
    getuser();
  }, []);

  const getuser = async () => {
    var userinfo = await getUserInfo();
    const filteruser = userinfo.Message.filter(
      (usr) =>
        usr.usertype != "Developer" &&
        usr.usertype != "Administrator" &&
        usr.userstatus === "Active"
    );

    setuserwithno(filteruser);
  };

  const handleFormSubmit = async (event) => {
    document.getElementById("btnSubmit").innerText = "Loading...";

    setSalesreportInformation([]);
    event.preventDefault();
    var post = new FormData();
    post.append("action", "salesReport");
    post.append("FromDate", salesinfo.fromDate);
    post.append("ToDate", salesinfo.toDate);
    if (typeReport === false) {
      post.append("Type", "General");
    } else {
      post.append("Type", "Date");
    }
    var salesreportInfo = await salesReport(post);

    if (salesreportInfo.status) {
      
      if (salesreportInfo.Message.length === 0) {
        showToastMessage("error", "No data Found.");
      } else {
        if (salesinfo.user_type == "" || salesinfo.user_type == undefined) {
          setSalesreportInformation(salesreportInfo.Message);
        } else {
          var filt = salesreportInfo.Message.filter(
            (username) => username.userid === salesinfo.user_type
          );
          if (filt.length === 0) {
            showToastMessage("error", "No data Found.");
          } else {
            setSalesreportInformation(filt);
          }
        }
        var regExpenseForm = document.getElementById("regExpenseForm");
        regExpenseForm.reset();
        setSalesInfo({
          fromDate: "",
          toDate: "",
        });
        setTypeReport(true);
      }
    } else {
      setSalesreportInformation([]);

      showToastMessage("error", salesreportInfo.Message);
    }
    document.getElementById("btnSubmit").innerText = "Search";
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSalesInfo({ ...salesinfo, [name]: value });
    if (name === "report_type") {
      if (value === "General") {
        setTypeReport(false);
      } else {
        setTypeReport(true);
      }
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(15);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = salesreportinformation.slice(
    firstPostIndex,
    lastPostIndex
  );

  const columns = [
    { title: "Code", field: "product_id" },
    { title: "Name", field: "product_name" },
    { title: "Category", field: "category_name" },
    { title: "Quantity", field: "product_quantity" },
    { title: "Unit Price", field: "Unit" },
    { title: "Total", field: "Credit" },
    { title: "Customer Name", field: "customer_name" },
    { title: "Selled By", field: "fname" },

    { title: "Date", field: "date" },
  ];

  const downloadPdf = () => {
    var tableData = salesreportinformation;
    for (let i = 0; i < tableData.length; i++) {
      const quantity = tableData[i].product_quantity;
      const total = tableData[i].Credit;
      const unit = total / quantity;
      tableData[i].Unit = parseFloat(unit);
    }
    var doc = new jsPDF();
    const imgData = myImage;

    // Add the image to the PDF
    doc.addImage(imgData, "JPEG", 80, 0, 50, 50); // Adjust the coordinates and dimensions as needed
    doc.text(process.env.REACT_APP_COMPANY_NAME, 70, 45);
    doc.text("SALES REPORT", 70, 55);
    const tableOptions = {
      startX: 20,
      startY: 65,
      margin: { top: 20, bottom: 20 },
      styles: { overflow: "linebreak" },
    };
    doc.autoTable({
      theme: "grid",
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: tableData,
      ...tableOptions,
    });
    var currentdate = new Date();
    var documentName =
      "Sales_Report " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " : " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds() +
      ".pdf";
    // console.log(documentName);
    doc.save(documentName);
  };

  const tableClass =
    theme.palette.mode === "dark"
      ? "table table-striped table-dark"
      : "table table-striped";
  const emptyColor = theme.palette.mode === "dark" ? "black" : "white";
  const selectBgColor = theme.palette.mode === "light" ? "#E8F0FE" : "#323848";
  const selectFgColor = theme.palette.mode === "light" ? "black" : "white";
  var totalquantity = 0;
  var totalunitprice = 0;
  var total = 0;

  return (
    <Box m="20px">
      <ToastContainer />
      <Header title="SALES REPORT" subtitle="" />

      <form id="regExpenseForm" onSubmit={handleFormSubmit}>
        <Box
          display="grid"
          gap="40px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <div className="row" style={{ width: "462%" }}>
            <div className="col-2">
              <select
                onChange={handleOnChange}
                fullWidth
                variant="filled"
                component="select"
                name="user_type"
                id="user_type"
                style={{
                  backgroundColor: selectBgColor,
                  color: selectFgColor,
                  height: "100%",
                  width: "100%",
                }}
              >
                <option value="">Select User</option>
                {userinfo_withnodevandadmin.map((usr, index) => (
                  <option key={index} value={usr.userid}>
                    {usr.user_fullname + " | " + usr.usertype}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-2">
              <select
                onChange={handleOnChange}
                fullWidth
                variant="filled"
                component="select"
                name="report_type"
                id="report_type"
                style={{
                  backgroundColor: selectBgColor,
                  color: selectFgColor,
                  height: "100%",
                  width: "100%",
                }}
                required
              >
                <option value="">Select Type</option>
                <option value="General">General</option>
                <option value="Date">By Date</option>
              </select>
            </div>

            <div className="col-3">
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="From Date"
                onChange={handleOnChange}
                // value={values.RegisterDate}
                inputProps={{
                  max: tomorrowFormatted,
                }}
                required={typeReport}
                disabled={!typeReport}
                name="fromDate"
              />
            </div>
            <div className="col-3">
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="To Date"
                inputProps={{
                  max: tomorrowFormatted,
                }}
                required={typeReport}
                disabled={!typeReport}
                onChange={handleOnChange}
                // value={values.RegisterDate}
                name="toDate"
              />
            </div>

            <div className="col-2">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                style={{ height: "100%", width: "100%" }}
                id="btnSubmit"
              >
                Search
              </Button>
            </div>
          </div>
        </Box>
      </form>
      <Box
        style={{
          justifyContent: "flex-end",
          display: "flex",
          marginTop: "1%",
        }}
      >
        <B onClick={downloadPdf}>
          <DownloadOutlinedIcon sx={{ mr: "10px" }} />
          Download
        </B>
      </Box>

      {salesreportinformation.length === 0 ? (
        <h1
          style={{
            color: { emptyColor },
            textAlign: "center",
            marginTop: "8%",
          }}
        >
          No data Found
        </h1>
      ) : (
        <>
          <Table className={tableClass} style={{ marginTop: "3%" }}>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Category</th>
                <th scope="col">Quantity</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Total</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Sell Type</th>
                <th scope="col">Selled By</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts.map((sale, index) => {
                // totalquantity += parseFloat(sale.product_quantity);
                // totalunitprice += parseFloat(
                //   parseFloat(sale.Credit) / parseFloat(sale.product_quantity)
                // );
                // total += parseFloat(sale.Credit);
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{sale.product_id}</td>
                    <td>{sale.product_name}</td>
                    <td>{sale.category_name}</td>
                    <td>{sale.product_quantity}</td>
                    <td>
                      $
                      {parseFloat(
                        parseFloat(sale.Credit) /
                          parseFloat(sale.product_quantity)
                      ).toLocaleString("en-US")}
                    </td>
                    <td>${parseFloat(sale.Credit).toLocaleString("en-US")}</td>
                    <td>{sale.customer_name}</td>
                     <td>{sale.sell_type}</td>
                    <td>{sale.fname}</td>
                    <td>{sale.date}</td>
                  </tr>
                );
              })}
              {salesreportinformation.map((sale, index) => {
                totalquantity += parseFloat(sale.product_quantity);
                totalunitprice += parseFloat(
                  parseFloat(sale.Credit) / parseFloat(sale.product_quantity)
                );
                total += parseFloat(sale.Credit);
              })}
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>{totalquantity}</td>
                <td>${totalunitprice.toLocaleString("en-US")}</td>
                <td>${total.toLocaleString("en-US")}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            </tbody>
          </Table>
          <Pagination
            totalPosts={salesreportinformation.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </>
      )}
    </Box>
  );
};

export default Form;
