import { Box, Button, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import {
  cancelSellorBuy,
  customerGiveCashReport,
  getCustomersInfo,
} from "../../api/financeapi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { Table, Modal } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import B from "react-bootstrap/Button";
import Pagination from "../../components/Pagination";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import myImage from "../system_logo.jpg";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Select from "react-select";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const showToastMessage = (type, message) => {
    toast(message, {
      type: type,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const [salesreportinformation, setSalesreportInformation] = useState([]);
  const [cusinfo, setcusinfo] = useState([]);
  const [salesinfo, setSalesInfo] = useState({
    customer_id: "",
    fromDate: "",
    toDate: "",
  });
  const [customer_information, setCustomerInfo] = useState([]);
    const [customerData, setcustomerData] = useState([]);
  const [customerVal, setcustomerVal] = useState("");
  const [show, setShow] = useState(false);
  const [deleteDate, setDeleteDate] = useState("");
  const [typeReport, setTypeReport] = useState(true);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().split("T")[0];
  useEffect(() => {
    getcustomerinfo();
  }, []);
  const getcustomerinfo = async () => {
    var customerInfo = await getCustomersInfo();
    setCustomerInfo(customerInfo.Message);
     var filter_customer = [];
        for (var i = 0; i < customerInfo.Message.length; i++) {
            filter_customer.push({
              value: customerInfo.Message[i].customer_id,
              label:
                customerInfo.Message[i].customer_name +
                " | " +
                customerInfo.Message[i].customer_phone,
            });
        }
        setcustomerData(filter_customer)
  };
  const handleFormSubmit = async (event) => {
    document.getElementById("btnSubmit").innerText = "Loading...";

    setSalesreportInformation([]);
    event.preventDefault();
    var post = new FormData();
    post.append("action", "customerGiveCashReport");
    // post.append("CustomerID", salesinfo.customer_id == "" ? "CUS":salesinfo.customer_id);
    post.append("CustomerID", customerVal.value == "" || customerVal.value == undefined ? "CUS":customerVal.value);
    post.append("FromDate", salesinfo.fromDate);
    post.append("ToDate", salesinfo.toDate);
    if (typeReport === false) {
      post.append("Type", "General");
    } else {
      post.append("Type", "Date");
    }
    var salesreportInfo = await customerGiveCashReport(post);

    if (salesreportInfo.status) {
      if (salesreportInfo.Message.length === 0)
        showToastMessage("error", "No data Found.");

      setSalesreportInformation(salesreportInfo.Message);
      var regExpenseForm = document.getElementById("regExpenseForm");
      regExpenseForm.reset();
      setSalesInfo({
        customer_id: "",
        fromDate: "",
        toDate: "",
      });
      setTypeReport(true);
    } else {
      setSalesreportInformation([]);

      showToastMessage("error", salesreportInfo.Message);
    }
    document.getElementById("btnSubmit").innerText = "Search";
  };

  const handleClickDelete = async (saleDate) => {
    setDeleteDate(saleDate);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClickDeleteButton = async () => {
    var post = new FormData();
    post.append("action", "cancelSellorBuy");
    post.append("RegisterDate", deleteDate);
    post.append("type", "sell");
    var ExpenseInfo = await cancelSellorBuy(post);

    if (ExpenseInfo.status) {
      showToastMessage("success", ExpenseInfo.Message);
    } else {
      showToastMessage("error", ExpenseInfo.Message);
    }
    setSalesreportInformation([]);
    setDeleteDate("");
    setShow(false);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSalesInfo({ ...salesinfo, [name]: value });
    if (name === "customer_id") {
      var selectElement = document.getElementById("customer_id");
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      const selectedText = selectedOption.text;
      setcusinfo({
        name: selectedText.split("|")[0],
        phone: selectedText.split("|")[1],
      });
    }

    if (name === "report_type") {
      if (value === "General") {
        setTypeReport(false);
      } else {
        setTypeReport(true);
      }
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = salesreportinformation.slice(
    firstPostIndex,
    lastPostIndex
  );
  // end pagination

  const columns = [
    { title: "Code", field: "product_id" },
    { title: "Name", field: "product_name" },
    { title: "Category", field: "category_name" },
    { title: "Quantity", field: "product_quantity" },
    { title: "Sell Type", field: "sell_type" },
    { title: "Unit Price", field: "Unit" },
    { title: "Total", field: "Credit" },
    { title: "Selled By", field: "fname" },

    { title: "Date", field: "date" },
  ];

  const downloadPdf = () => {
    if (salesreportinformation.length === 0) {
      showToastMessage("error", "No data found");
      return;
    }
    var tableData = salesreportinformation;
    for (let i = 0; i < tableData.length; i++) {
      const quantity = tableData[i].product_quantity;
      const total = tableData[i].Credit;
      const unit = total / quantity;
      tableData[i].Unit = parseFloat(unit);
    }
    var doc = new jsPDF();
    const imgData = myImage;

    // Add the image to the PDF
    doc.addImage(imgData, "JPEG", 80, 0, 50, 50); // Adjust the coordinates and dimensions as needed
    doc.text(process.env.REACT_APP_COMPANY_NAME, 70, 45);
    doc.text("CUSTOMER GIVE CASH REPORT", 70, 55);

    const fontSize = 12; // Specify the desired font size
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(fontSize);
    doc.text("Customer Name: ", 20, 65);
    doc.text(cusinfo.name, 55, 65);
    doc.text("Customer Phone: ", 120, 65);
    doc.text(cusinfo.phone, 153, 65);
    const tableOptions = {
      startX: 20,
      startY: 75,
      margin: { top: 20, bottom: 20 },
      styles: { overflow: "linebreak" },
    };
    doc.autoTable({
      theme: "grid",
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: tableData,
      ...tableOptions,
    });
    var currentdate = new Date();
    var documentName =
      "Customer_Report " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " : " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds() +
      ".pdf";
    // console.log(documentName);
    doc.save(documentName);
  };

  const tableClass =
    theme.palette.mode === "dark"
      ? "table table-striped table-dark"
      : "table table-striped";
  const emptyColor = theme.palette.mode === "dark" ? "black" : "white";
  const selectBgColor = theme.palette.mode === "light" ? "#E8F0FE" : "#323848";
  const selectFgColor = theme.palette.mode === "light" ? "black" : "white";
  var totalquantity = 0;
  var totalunitprice = 0;
  var total = 0;

  return (
    <>
      {/* <Modal show={show} onHide={handleClose} style={{ color: "black" }}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel this Sell?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this Sell?</Modal.Body>
        <Modal.Footer>
          <B variant="primary" onClick={handleClickDeleteButton}>
            Yes
          </B>
          <B variant="danger" onClick={handleClose}>
            No
          </B>
        </Modal.Footer>
      </Modal> */}
      <Box m="20px">
        <ToastContainer />
        <Header title="CUSTOMER GIVE CASH REPORT" subtitle="" />

        <form id="regExpenseForm" onSubmit={handleFormSubmit}>
          <Box
            display="grid"
            gap="40px"
            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <div className="row" style={{ width: "600%" }}>
              <div className="col-4">
                {/* <select
                  onChange={handleOnChange}
                  fullWidth
                  variant="filled"
                  component="select"
                  name="customer_id"
                  id="customer_id"
                  style={{
                    backgroundColor: selectBgColor,
                    color: selectFgColor,
                    height: "100%",
                    width: "100%",
                  }}
                  // required
                >
                  <option value="">Select Customer</option>
                  {customer_information.map((customer, index) => (
                    <option key={index} value={customer.customer_id}>
                      {customer.customer_name + " | " + customer.customer_phone}
                    </option>
                  ))}
                </select> */}
                <Select
                  value={customerVal}
                  styles={{
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      // color: "black",
                      backgroundColor: selectBgColor,
                      color: selectFgColor,
                      height: "100%",
                      width: "100%",
                    }),
                  }}
                  name="customer_id"
                  id="customer_id"
                  options={customerData}
                  onChange={setcustomerVal}
                  // required
                />
              </div>
              <div className="col-2">
                <select
                  onChange={handleOnChange}
                  fullWidth
                  variant="filled"
                  component="select"
                  name="report_type"
                  id="report_type"
                  style={{
                    backgroundColor: selectBgColor,
                    color: selectFgColor,
                    height: "100%",
                    width: "100%",
                  }}
                  required
                >
                  <option value="">Select Type</option>
                  <option value="General">General</option>
                  <option value="Date">By Date</option>
                </select>
              </div>

              <div className="col-2">
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="From Date"
                  onChange={handleOnChange}
                  // value={values.RegisterDate}
                  inputProps={{
                    max: tomorrowFormatted,
                  }}
                  name="fromDate"
                  // sx={{ gridColumn: "span 2" }}
                  required={typeReport}
                  disabled={!typeReport}
                />
              </div>
              <div className="col-2">
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  inputProps={{
                    max: tomorrowFormatted,
                  }}
                  label="To Date"
                  onChange={handleOnChange}
                  // value={values.RegisterDate}
                  name="toDate"
                  // sx={{ gridColumn: "span 2" }}
                  required={typeReport}
                  disabled={!typeReport}
                />
              </div>

              <div className="col-2 ">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  style={{ height: "100%", width: "100%" }}
                  id="btnSubmit"
                >
                  Search
                </Button>
              </div>
            </div>
          </Box>
        </form>
        {/* <Box
          style={{
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "1%",
          }}
        >
          <B onClick={downloadPdf}>
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download
          </B>
        </Box> */}

        {salesreportinformation.length === 0 ? (
          <h1
            style={{
              color: { emptyColor },
              textAlign: "center",
              marginTop: "8%",
            }}
          >
            No data Found
          </h1>
        ) : (
          <>
            <Table className={tableClass} style={{ marginTop: "3%" }}>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Money</th> 
                  <th scope="col">Date</th>
                  {/* <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((sale, index) => {
                  // totalquantity += parseFloat(sale.product_quantity);
                  // totalunitprice += parseFloat(
                  //   parseFloat(sale.Credit) / parseFloat(sale.product_quantity)
                  // );
                  // total += parseFloat(sale.Credit);
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{sale.customer_name}</td>
                      <td>
                        ${parseFloat(sale.Credit).toLocaleString("en-US")}
                      </td>
                      <td>{sale.date}</td>
                      {/* <td>
                        <B
                          type="button"
                          className="btn btn-danger btn-sm"
                          variant="contained"
                          onClick={() => handleClickDelete(sale.date)}
                        >
                          <AiFillCloseCircle style={{ color: "white" }} />
                        </B>{" "}
                      </td> */}
                    </tr>
                  );
                })}
                {salesreportinformation.map((sale, index) => {
                  total += parseFloat(sale.Credit);
                })}
                <tr>
                  <td>-</td>
                  <td>-</td>
                  <td>${total.toLocaleString("en-US")}</td>
                  <td>-</td>
                </tr>
              </tbody>
            </Table>
            <Pagination
              totalPosts={salesreportinformation.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default Form;
