import { Box, Button, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCodeNumber, registerProduct } from "../../api/productapi";
import { getcategoryInfo } from "../../api/userapi";
import { setBankAccountInfo, setCategoryInfo } from "../../feature/userSlice";
import { getBankAccount, getSupplierInfo } from "../../api/financeapi";

const Form = () => {
  const dispatch = useDispatch();
  const { categoryinformation, bankaccountinformation } = useSelector(
    (store) => store.userReducer
  );
  const active_categoryinformation = categoryinformation.filter(
    (cat) => cat.category_status === "Active"
  );
  const [isLiability, setIsLiability] = useState(false);
  const [supplier_information, setSupplierInfo] = useState([]);

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const [isSubmitButton, setIsSubmitButton] = useState(false);
  const showToastMessage = (type, message) => {
    toast(message, {
      type: type,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  useEffect(() => {
    getcode();
  }, []);
  const getcode = async () => {
    var codenumber = await getCodeNumber();
    var categoryinfo = await getcategoryInfo();
    var bankaccountinfo = await getBankAccount();
    var supplierinfo = await getSupplierInfo();
    setProductInfo({ ...productinfo, ["CodeNumber"]: codenumber.Message });
    dispatch(setCategoryInfo(categoryinfo.Message));
    dispatch(setBankAccountInfo(bankaccountinfo.Message));
    setSupplierInfo(supplierinfo.Message);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      parseFloat(productinfo.SellingPrice) < parseFloat(productinfo.BuyingPrice)
    ) {
      showToastMessage(
        "error",
        "Qiimaha la gadaayo waa in uu ka badnaadaa lafaha"
      );
      document.getElementById("SellingPrice").value = "";
      return;
    }
    setIsSubmitButton(true);
    var post = new FormData();
    post.append("action", "registerProduct");
    post.append("ProductName", productinfo.ProductName);
    post.append("CategoryID", productinfo.CategoryID);
    post.append("ProductQuantity", productinfo.ProductQuantity);
    post.append("BuyingPrice", productinfo.BuyingPrice);
    post.append("SellingPrice", productinfo.SellingPrice);
    post.append("BuyType", productinfo.BuyType);
    if (productinfo.BuyType === "Cash") {
      post.append("AccountType", productinfo.AccountType);
      post.append("SupplierID", "-");
    } else {
      post.append("SupplierID", productinfo.SupplierID);
      post.append("AccountType", "-");
    }
    var product_result = await registerProduct(post);
    // console.log(product_result);
    // return;
    if (product_result.status) {
      getcode();
      showToastMessage("success", product_result.Message);
      var regProductForm = document.getElementById("regProductForm");
      regProductForm.reset();
      setProductInfo({
        ProductName: "",
        CategoryID: "",
        ProductQuantity: "",
        CodeNumber: "",
        BuyingPrice: "",
        SellingPrice: "",
        BuyType: "",
        AccountType: "",
        SupplierID: "",
      });
    } else {
      showToastMessage("error", product_result.Message);
    }
    setIsSubmitButton(false);
  };
  const [productinfo, setProductInfo] = useState({
    ProductName: "",
    CategoryID: "",
    ProductQuantity: "",
    CodeNumber: "",
    BuyingPrice: "",
    SellingPrice: "",
    BuyType: "",
    AccountType: "",
    SupplierID: "",
  });

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setProductInfo({ ...productinfo, [name]: value });

    if (name === "BuyType") {
      if (value === "Liability") {
        setIsLiability(true);
      } else {
        setIsLiability(false);
      }
    }

    if (name === "ProductQuantity") {
      var val = parseFloat(value);
      if (!(typeof val === "number" && !isNaN(val))) {
        showToastMessage("error", "Fadlan soo gali number");
        document.getElementById("ProductQuantity").value = "";
      } else {
        if (typeof val === "number" && val < 0) {
          showToastMessage("error", "Quantity waa in uu ka bataa 0");
          document.getElementById("ProductQuantity").value = "";
        }
      }
    }
    if (name === "BuyingPrice") {
      val = parseFloat(value);
      if (!(typeof val === "number" && !isNaN(val))) {
        showToastMessage("error", "Fadlan soo gali number");
        document.getElementById("BuyingPrice").value = "";
      } else {
        if (typeof val === "number" && val < 0) {
          showToastMessage("error", "Buying Price waa in uu ka weynaadaa 0");
          document.getElementById("BuyingPrice").value = "";
        }
      }
    }
    if (name === "SellingPrice") {
      val = parseFloat(value);
      if (!(typeof val === "number" && !isNaN(val))) {
        showToastMessage("error", "Fadlan soo gali number");
        document.getElementById("SellingPrice").value = "";
      } else {
        if (typeof val === "number" && val < 0) {
          showToastMessage("error", "Selling Price waa in uu ka weynaadaa 0");
          document.getElementById("SellingPrice").value = "";
        }
      }
    }
  };
  const selectBgColor = theme.palette.mode === "light" ? "#E8F0FE" : "#323848";
  const selectFgColor = theme.palette.mode === "light" ? "black" : "white";
  return (
    <Box m="20px">
      <ToastContainer />
      <Header title="REGISTER PRODUCT" subtitle="Create a New Product" />
      <form id="regProductForm" onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Product Name"
            name="ProductName"
            sx={{ gridColumn: "span 2" }}
            onChange={handleOnChange}
            required
          />
          <select
            onChange={handleOnChange}
            fullWidth
            variant="filled"
            component="select"
            name="CategoryID"
            id="CategoryID"
            style={{
              gridColumn: "span 2",
              backgroundColor: selectBgColor,
              color: selectFgColor,
              // height: "260%",
            }}
            required
          >
            <option value="">Select Category</option>
            {active_categoryinformation.map((category, index) => (
              <option key={index} value={category.category_id}>
                {category.category_name}
              </option>
            ))}
          </select>
          <TextField
            fullWidth
            variant="filled"
            type="number"
            min="0"
            label="Product Quantity"
            name="ProductQuantity"
            id="ProductQuantity"
            // value={productinfo.ProductQuantity}
            sx={{ gridColumn: "span 2" }}
            onChange={handleOnChange}
            required
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Code Number"
            name="CodeNumber"
            id="CodeNumber"
            value={productinfo.CodeNumber}
            sx={{ gridColumn: "span 2" }}
            onChange={handleOnChange}
            InputProps={{ readOnly: true }}
          />

          <TextField
            fullWidth
            variant="filled"
            type="number"
            InputProps={{ inputProps: { step: "0.01" } }}
            label="Buying Price"
            name="BuyingPrice"
            id="BuyingPrice"
            // value={productinfo.BuyingPrice}
            sx={{ gridColumn: "span 2" }}
            onChange={handleOnChange}
            required
          />

          <TextField
            fullWidth
            variant="filled"
            type="number"
            InputProps={{ inputProps: { step: "0.01" } }}
            label="Selling Price"
            name="SellingPrice"
            id="SellingPrice"
            // value={productinfo.SellingPrice}
            sx={{ gridColumn: "span 2" }}
            onChange={handleOnChange}
            required
          />
          <select
            onChange={handleOnChange}
            fullWidth
            variant="filled"
            component="select"
            name="BuyType"
            id="BuyType"
            style={{
              gridColumn: "span 2",
              backgroundColor: selectBgColor,
              color: selectFgColor,
              height: "240%",
            }}
            required
          >
            <option value="">Select Type</option>
            <option value="Cash">Cash</option>
            <option value="Liability">Liability/Deyn</option>
          </select>
          {isLiability === true ? (
            <select
              onChange={handleOnChange}
              fullWidth
              variant="filled"
              component="select"
              name="SupplierID"
              id="SupplierID"
              style={{
                gridColumn: "span 2",
                backgroundColor: selectBgColor,
                color: selectFgColor,
                height: "240%",
              }}
              required
            >
              <option value="">Select Supplier</option>
              {supplier_information.map((supplier, index) => (
                <option key={index} value={supplier.supplier_id}>
                  {supplier.supplier_name + " | " + supplier.supplier_phone}
                </option>
              ))}
            </select>
          ) : (
            <select
              onChange={handleOnChange}
              fullWidth
              variant="filled"
              component="select"
              name="AccountType"
              id="AccountType"
              style={{
                gridColumn: "span 2",
                backgroundColor: selectBgColor,
                color: selectFgColor,
                height: "240%",
              }}
              required
            >
              <option value="">Select Account</option>
              {bankaccountinformation.map((account, index) => (
                <option key={index} value={account.bank_id}>
                  {account.bank_name}
                </option>
              ))}
            </select>
          )}

          {isSubmitButton === true ? (
            <Button
              type="button"
              style={
                isLiability
                  ? { height: "100%", width: "80%", marginTop: "10%" }
                  : { height: "100%", width: "80%", marginTop: "10%" }
              }
              color="secondary"
              variant="contained"
            >
              Loading........
            </Button>
          ) : (
            <Button
              type="submit"
              style={
                isLiability
                  ? { height: "100%", width: "80%", marginTop: "10%" }
                  : { height: "100%", width: "80%", marginTop: "10%" }
              }
              color="secondary"
              variant="contained"
            >
              Create New Product
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default Form;
