import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import AddUser from "./scenes/user/AddUser";
import ViewUser from "./scenes/user/ViewUser";
import Category from "./scenes/category/Category";
import AddProduct from "./scenes/product/AddProduct";
import ViewProduct from "./scenes/product/ViewProduct";
import SellProduct from "./scenes/product/SellProduct";
import Account from "./scenes/finance/Account";
import Expense from "./scenes/finance/Expense";
import Drawing from "./scenes/finance/Drawing";
import Customer from "./scenes/finance/Customer";
import Supplier from "./scenes/finance/Supplier";
import SalesReport from "./scenes/reports/Sales";
import CustomerReport from "./scenes/reports/CustomerReport";
import CustomerGiveCashReport from "./scenes/reports/CustomerGiveCashReport";
import PaymentReport from "./scenes/reports/PaymentReport";
import ExpenseReport from "./scenes/reports/ExpenseReport";
import SupplierReport from "./scenes/reports/SupplierReport";
import IncomeReport from "./scenes/reports/IncomeStatement";
import BalanceSheetReport from "./scenes/reports/BalanceSheet.jsx";
import OwnersEquityReport from "./scenes/reports/OwnersEquity.jsx";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
// import { useSelector } from "react-redux";

function App({ root }) {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  // const { userinformation } = useSelector((store) => store.userReducer);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar root={root} setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/adduser" element={<AddUser />} />
              <Route path="/viewuser" element={<ViewUser />} />
              <Route path="/category" element={<Category />} />
              <Route path="/addproduct" element={<AddProduct />} />
              <Route path="/viewproduct" element={<ViewProduct />} />
              <Route path="/sellproduct" element={<SellProduct />} />
              <Route path="/account" element={<Account />} />
              <Route path="/expense" element={<Expense />} />
               <Route path="/drawing" element={<Drawing />} />
              <Route path="/customer" element={<Customer />} />
              <Route path="/supplier" element={<Supplier />} />
              <Route path="/customerreport" element={<CustomerReport />} />
              <Route path="/customergivecashreport" element={<CustomerGiveCashReport />} />
              <Route path="/supplierreport" element={<SupplierReport />} />
              <Route path="/paymentreport" element={<PaymentReport />} />
              <Route path="/expensereport" element={<ExpenseReport />} />
              <Route path="/salesreport" element={<SalesReport />} />
              <Route path="/incomereport" element={<IncomeReport />} />
              <Route
                path="/balancsheetreport"
                element={<BalanceSheetReport />}
              />
              <Route path="/ownersreport" element={<OwnersEquityReport />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
