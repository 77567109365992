import { Box, Button, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import {
  cancelDrawing,
  getBankAccount,
  getDrawings,
  registerDrawing,
} from "../../api/financeapi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { Table, Modal } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import B from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { setBankAccountInfo } from "../../feature/userSlice";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import myImage from "../system_logo.jpg";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const showToastMessage = (type, message) => {
    toast(message, {
      type: type,
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { bankaccountinformation } = useSelector((store) => store.userReducer);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [expenseinformation, setExpenseInformation] = useState([]);
  const [expenseinfo, setExpenseInfo] = useState({
    Description: "",
    AccountType: "",
    Money: "",
    RegisterDate: "",
  });
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().slice(0, 16);
  useEffect(() => {
    getexpenses();
  }, []);

  const getexpenses = async () => {
    var post = {
    action: "getDrawings",
    type:"T"
  };
    var expinfo = await getDrawings(post);
    var bankaccountinfo = await getBankAccount();
    dispatch(setBankAccountInfo(bankaccountinfo.Message));
    setExpenseInformation(expinfo.Message);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const handleFormSubmit = async (event) => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);
    }
    event.preventDefault();

    if (expenseinfo.Money <= 0) {
      showToastMessage("error", "Lacagta waa in ay ka badataa 0");
      return;
    }
    const datetime = new Date(expenseinfo.RegisterDate);
    const randomNumber = Math.floor(Math.random() * 30) + 1;
    datetime.setSeconds(datetime.getSeconds() + randomNumber);
    const year = datetime.getFullYear();
    const month = String(datetime.getMonth() + 1).padStart(2, "0");
    const day = String(datetime.getDate()).padStart(2, "0");
    const hours = String(datetime.getHours()).padStart(2, "0");
    const minutes = String(datetime.getMinutes()).padStart(2, "0");
    const seconds = String(datetime.getSeconds()).padStart(2, "0");

    const formattedDatetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    var post = new FormData();
    post.append("action", "registerDrawing");
    post.append("Money", expenseinfo.Money);
    post.append("AccountType", expenseinfo.AccountType);
    post.append("Description", expenseinfo.Description);
    post.append("RegisterDate", formattedDatetime);
    var ExpenseInfo = await registerDrawing(post);

    if (ExpenseInfo.status) {
      showToastMessage("success", ExpenseInfo.Message);
      var regExpenseForm = document.getElementById("regExpenseForm");
      regExpenseForm.reset();
      setExpenseInfo({
        Description: "",
        AccountType: "",
        Money: "",
        RegisterDate: "",
      });
      getexpenses();
    } else {
      showToastMessage("error", ExpenseInfo.Message);
    }
    setIsButtonDisabled(false);
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setExpenseInfo({ ...expenseinfo, [name]: value });

    if (name === "Money") {
      var val = parseFloat(value);
      if (!(typeof val === "number" && !isNaN(val))) {
        showToastMessage("error", "Fadlan soo gali number");
        setExpenseInfo({ ...expenseinfo, [name]: "" });
        document.getElementById("Money").value = "";
      } else {
        if (typeof val === "number" && val < 0) {
          showToastMessage("error", "Lacagta waa in ay ka badataa 0");
          setExpenseInfo({ ...expenseinfo, [name]: "" });
          document.getElementById("Money").value = "";
        }
      }
    }
  };
  const handleClickEditCat = async () => {
    var post = new FormData();
    post.append("action", "cancelDrawing");
    post.append("RegisterDate", expenseinformation[deleteId].transaction_date);
    var ExpenseInfo = await cancelDrawing(post);

    if (ExpenseInfo.status) {
      showToastMessage("success", ExpenseInfo.Message);
    } else {
      showToastMessage("error", ExpenseInfo.Message);
    }
    getexpenses();
    setDeleteId("");
    setShow(false);
  };

  const columns = [
    { title: "Name", field: "account_name" },
    { title: "Money", field: "Debit" },
    { title: "Description", field: "description" },

    { title: "Register Date", field: "transaction_date" },
  ];

  const downloadPdf = () => {
    var tableData = expenseinformation;
    for (let i = 0; i < tableData.length; i++) {
      tableData[i].Paid = parseFloat(tableData[i].Paid).toFixed(2);
      tableData[i].Liability = parseFloat(tableData[i].Liability).toFixed(2);
      const paid = tableData[i].Paid;
      const liability = tableData[i].Liability;
      const due = liability - paid;
      tableData[i].Due = parseFloat(due).toFixed(2);
    }
    var doc = new jsPDF();
    const imgData = myImage;

    // Add the image to the PDF
    doc.addImage(imgData, "JPEG", 80, 0, 50, 50); // Adjust the coordinates and dimensions as needed
    doc.text(process.env.REACT_APP_COMPANY_NAME, 70, 45);
    doc.text("EXPENSE INFORMATION", 70, 55);
    const tableOptions = {
      startX: 20,
      startY: 65,
      margin: { top: 20, bottom: 20 },
      styles: { overflow: "linebreak" },
    };
    doc.autoTable({
      theme: "grid",
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: tableData,
      ...tableOptions,
    });
    var currentdate = new Date();
    var documentName =
      "Expense " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " : " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds() +
      ".pdf";
    // console.log(documentName);
    doc.save(documentName);
  };

  const selectBgColor = theme.palette.mode === "light" ? "#E8F0FE" : "#323848";
  const selectFgColor = theme.palette.mode === "light" ? "black" : "white";
  const tableClass =
    theme.palette.mode === "dark"
      ? "table table-striped table-dark"
      : "table table-striped";
  const emptyColor = theme.palette.mode === "dark" ? "black" : "white";

  return (
    <>
      <Modal show={show} onHide={handleClose} style={{ color: "black" }}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel this Drawing?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this Drawing?</Modal.Body>
        <Modal.Footer>
          <B variant="primary" onClick={handleClickEditCat}>
            Yes
          </B>
          <B variant="danger" onClick={handleClose}>
            No
          </B>
        </Modal.Footer>
      </Modal>
      <Box m="20px">
        <ToastContainer />
        <Header title="REGISTER Owner's Drawing" subtitle="Register a New Drawing" />

        <form id="regExpenseForm" onSubmit={handleFormSubmit}>
          <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <select
              fullWidth
              variant="filled"
              component="select"
              name="AccountType"
              value={expenseinfo.AccountType}
              onChange={handleOnChange}
              style={{
                gridColumn: "span 1",
                backgroundColor: selectBgColor,
                color: selectFgColor,
                // height: "150%",
              }}
              required
            >
              <option value="">Select Account</option>
              {bankaccountinformation.map((account, index) => (
                <option key={index} value={account.bank_id}>
                  {account.bank_name}
                </option>
              ))}
            </select>
            <TextField
              fullWidth
              variant="filled"
              type="number"
              InputProps={{ inputProps: { step: "0.01" } }}
              label="Money"
              onChange={handleOnChange}
              // value={values.Money}
              name="Money"
              id="Money"
              value={expenseinfo.Money}
              sx={{ gridColumn: "span 1" }}
              required
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Description"
              onChange={handleOnChange}
              name="Description"
              id="Description"
              value={expenseinfo.Description}
              sx={{ gridColumn: "span 1" }}
              required
            />

            <TextField
              fullWidth
              variant="filled"
              type="datetime-local"
              label="Register Date"
              onChange={handleOnChange}
              inputProps={{
                max: tomorrowFormatted,
              }}
              value={expenseinfo.RegisterDate}
              name="RegisterDate"
              id="RegisterDate"
              sx={{ gridColumn: "span 1" }}
              required
            />

            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={isButtonDisabled}
            >
              Register New Drawing
            </Button>
          </Box>
        </form>
        <Box
          style={{
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "1%",
          }}
        >
          <B onClick={downloadPdf}>
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download
          </B>
        </Box>
        {expenseinformation.length === 0 ? (
          <h1
            style={{
              color: { emptyColor },
              textAlign: "center",
              marginTop: "8%",
            }}
          >
            No data Found
          </h1>
        ) : (
          <Table className={tableClass} style={{ marginTop: "3%" }}>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Money</th>
                <th scope="col">Description</th>
                <th scope="col">Register Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {expenseinformation.map((expense, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>${parseFloat(expense.Debit).toLocaleString("en-US")}</td>
                  <td>{expense.description}</td>
                  <td>{expense.transaction_date}</td>

                  <td>
                    <B
                      type="button"
                      className="btn btn-danger btn-sm"
                      variant="contained"
                      onClick={() => handleClickDelete(index)}
                    >
                      <AiFillCloseCircle style={{ color: "white" }} />
                    </B>{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Box>
    </>
  );
};

export default Form;
