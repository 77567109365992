import React from 'react'
import "./style.css"

function Loading() {
  return (
    <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    )
}

export default Loading