import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { handleModal, setCurrentproductInfo } from "../../feature/productSlice";
import { Button, Form } from "react-bootstrap";
import { AiFillEdit, AiFillCloseCircle } from "react-icons/ai";
import styles from "./modal.module.scss";
import { updateProduct } from "../../api/productapi";
import { getSupplierInfo } from "../../api/financeapi";

const customStyles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// setting up the main element
ReactModal.setAppElement("#root");
function Modal({ showToastMessage, getproductInfo }) {
  const dispatch = useDispatch();

  const { isOpen, currentProductInfo } = useSelector(
    (store) => store.productReducer
  );
  const { categoryinformation, bankaccountinformation } = useSelector(
    (store) => store.userReducer
  );
  const active_categoryinformation = categoryinformation.filter(
    (cat) => cat.category_status === "Active"
  );

  const [supplier_information, setSupplierInfo] = useState([]);
  function closeModal() {
    dispatch(handleModal());
    dispatch(setCurrentproductInfo([]));
    setCurrentProductInfo({});
    setIsSameProductQuantity(false);
  }
  useEffect(() => {
    getcode();
    setCurrentProductInfo({
      product_id: currentProductInfo.product_id,
      product_name: currentProductInfo.product_name,
      category_id: currentProductInfo.category_id,
      product_quantity: currentProductInfo.product_quantity,
      product_buying_price: currentProductInfo.product_buying_price,
      product_selling_price: currentProductInfo.product_selling_price,
      product_buying_type: currentProductInfo.product_buying_type,
      supplier_id: "",
      product_reg_date: currentProductInfo.product_reg_date,
    });
    if (
      currentProductInfo.product_quantity ===
      currentProductInfo.current_quantity
    ) {
      setIsSameProductQuantity(true);
    } else {
      setIsSameProductQuantity(false);
    }
    // if (currentProductInfo.product_buying_type === "Liability")
    //   setIsLiability(true);
    // else setIsLiability(false);
  }, [currentProductInfo]);

  const getcode = async () => {
    var supplierinfo = await getSupplierInfo();

    setSupplierInfo(supplierinfo.Message);
  };

  const [isLiability, setIsLiability] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSameProductQuantity, setIsSameProductQuantity] = useState(false);
  const [currentProduct, setCurrentProductInfo] = useState({
    product_id: "",
    product_name: "",
    category_id: "",
    product_quantity: "",
    product_buying_price: "",
    product_selling_price: "",
    product_buying_type: "",
    bank_accounts: "",
    supplier_id: "",
    product_reg_date: "",
  });
  if (currentProductInfo.length === 0) return;
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setCurrentProductInfo({ ...currentProduct, [name]: value });

    if (name === "product_buying_type") {
      if (value === "Liability") {
        setIsLiability(true);
      } else {
        setIsLiability(false);
      }
    }

    if (name === "product_quantity") {
      var val = parseFloat(value);
      if (!(typeof val === "number" && !isNaN(val))) {
        showToastMessage("error", "Fadlan soo gali number");
        setCurrentProductInfo({ ...currentProduct, [name]: "" });
      } else {
        if (typeof val === "number" && val < 0) {
          showToastMessage("error", "Quantity waa in uu ka weynaadaa 0");
          setCurrentProductInfo({ ...currentProduct, [name]: "" });
          // document.getElementById("product_quantity").value = "";
        }
      }
    }
    if (name === "product_buying_price") {
      val = parseFloat(value);
      if (!(typeof val === "number" && !isNaN(val))) {
        showToastMessage("error", "Fadlan soo gali number");
        setCurrentProductInfo({ ...currentProduct, [name]: "" });
      } else {
        if (typeof val === "number" && val < 0) {
          showToastMessage("error", "Buying Price waa in uu ka weynaadaa 0");
          setCurrentProductInfo({ ...currentProduct, [name]: "" });
        }
      }
    }
    if (name === "product_selling_price") {
      val = parseFloat(value);
      if (!(typeof val === "number" && !isNaN(val))) {
        showToastMessage("error", "Fadlan soo gali number");
        setCurrentProductInfo({ ...currentProduct, [name]: "" });
      } else {
        if (typeof val === "number" && val < 0) {
          showToastMessage("error", "Selling Price waa in uu ka weynaadaa 0");
          setCurrentProductInfo({ ...currentProduct, [name]: "" });
        }
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // var productquan =
    //   parseInt(currentProductInfo.product_quantity) +
    //   parseInt(currentProduct.product_quantity);
    setIsSubmit(true);
    var post = new FormData();
    post.append("action", "updateProduct");
    post.append("ProductID", currentProduct.product_id);
    post.append("ProductName", currentProduct.product_name);
    post.append("CategoryID", currentProduct.category_id);
    post.append("ProductQuantity", currentProduct.product_quantity);
    post.append("BuyingPrice", currentProduct.product_buying_price);
    post.append("SellingPrice", currentProduct.product_selling_price);
    post.append("BuyType", currentProduct.product_buying_type);
    post.append("ProductDate", currentProduct.product_reg_date);
    if (currentProduct.product_buying_type === "Cash") {
      post.append("AccountType", currentProduct.bank_accounts);
      post.append("SupplierID", "-");
    } else {
      post.append("SupplierID", currentProduct.supplier_id);
      post.append("AccountType", "-");
    }
    post.append("Up", "0");
    var productinfo = await updateProduct(post);
    // console.log(productinfo);
    // return;
    setIsSubmit(false);
    if (productinfo.status) {
      closeModal();
      showToastMessage("success", productinfo.Message);
      getproductInfo();
    } else {
      showToastMessage("error", productinfo.Message);
    }
  };

  // var selectLiabilitybody = "";
  // if (isSameProductQuantity === true && isLiability === true) {
  //   selectLiabilitybody = (
  //     <>
  //       <option value="Cash">Cash</option>
  //       <option value="Liability">Liability/Deyn</option>
  //     </>
  //   );
  // } else if (isSameProductQuantity === false && isLiability === false) {
  //   selectLiabilitybody = <option value="Cash">Cash</option>;
  // } else if (isSameProductQuantity === true && isLiability === false) {
  //   selectLiabilitybody = (
  //     <>
  //       <option value="Cash">Cash</option>
  //       <option value="Liability">Liability/Deyn</option>
  //     </>
  //   );
  // } else if (isSameProductQuantity === false && isLiability === true) {
  //   selectLiabilitybody = <option value="Liability">Liability/Deyn</option>;
  // }
  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2
          style={{
            textAlign: "center",
            margin: "3%",
            fontWeight: "bold",
            marginBottom: "2%",
          }}
        >
          UPDATE PRODUCT INFORMATION
        </h2>
        <Form className={styles.form} onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="form-group col-md-6">
              <label>Product Code</label>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Product Code"
                name="product_id"
                value={currentProduct.product_id}
                onChange={handleOnChange}
                readOnly
              />
            </div>
            <div className="form-group col-md-6">
              <label>Product Name</label>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Product Name"
                name="product_name"
                value={currentProduct.product_name}
                onChange={handleOnChange}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="form-group col-md-6">
              <label>Category</label>
              <select
                className="form-select"
                name="category_id"
                value={currentProduct.category_id}
                onChange={handleOnChange}
                required
              >
                <option value="">Select Category</option>
                {active_categoryinformation.map((category, index) => (
                  <option key={index} value={category.category_id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-md-6">
              <label>Product Quantity</label>
              <Form.Control
                type="number"
                min="1"
                className="form-control"
                placeholder="Product Quantity"
                name="product_quantity"
                value={currentProduct.product_quantity}
                onChange={handleOnChange}
                required
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="form-group col-md-6">
              <label>Buying Price</label>
              {isSameProductQuantity === true ? (
                <Form.Control
                  type="number"
                  step="0.1"
                  className="form-control"
                  placeholder="Buying Price"
                  name="product_buying_price"
                  value={currentProduct.product_buying_price}
                  onChange={handleOnChange}
                  required
                />
              ) : (
                <Form.Control
                  type="number"
                  step="0.1"
                  className="form-control"
                  placeholder="Buying Price"
                  name="product_buying_price"
                  value={currentProduct.product_buying_price}
                  onChange={handleOnChange}
                  readOnly
                />
              )}
            </div>
            <div className="form-group col-md-6">
              <label>Selling Price</label>
              <Form.Control
                type="number"
                step="0.1"
                className="form-control"
                placeholder="Selling Price"
                name="product_selling_price"
                value={currentProduct.product_selling_price}
                onChange={handleOnChange}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label>Buying Type</label>

              <select
                className="form-select"
                name="product_buying_type"
                // value={currentProduct.product_buying_type}
                onChange={handleOnChange}
                required
              >
                <option value="">Select Type</option>
                <option value="Cash">Cash</option>
                <option value="Liability">Liability/Deyn</option>
                {/* {selectLiabilitybody} */}
              </select>
            </div>
            {isLiability ? (
              <>
                <div className="form-group col-md-6">
                  <label>Supplier</label>
                  <select
                    className="form-select"
                    name="supplier_id"
                    // value={currentProduct.category_id}
                    onChange={handleOnChange}
                    required
                  >
                    <option value="">Select Supplier</option>
                    {supplier_information.map((supplier, index) => (
                      <option key={index} value={supplier.supplier_id}>
                        {supplier.supplier_name +
                          " | " +
                          supplier.supplier_phone}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="form-group col-md-6 mt-4"
                  style={{ marginLeft: "30%" }}
                >
                  <Button type="submit" className="btn btn-primary">
                    <AiFillEdit /> Update
                  </Button>

                  <Button
                    type="button"
                    className="btn btn-danger"
                    onClick={closeModal}
                    style={{ marginLeft: "20%" }}
                  >
                    <AiFillCloseCircle /> Close
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="form-group col-md-6">
                  <label>Bank Accounts</label>
                  <select
                    className="form-select"
                    name="bank_accounts"
                    // value={currentProduct.category_id}
                    onChange={handleOnChange}
                    required
                  >
                    <option value="">Select Account</option>
                    {bankaccountinformation.map((account, index) => (
                      <option key={index} value={account.bank_id}>
                        {account.bank_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="form-group col-md-6 mt-4"
                  style={{ marginLeft: "30%" }}
                >
                  {isSubmit === true ? (
                    <Button type="button" className="btn btn-primary">
                      <AiFillEdit /> Loading
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-primary">
                      <AiFillEdit /> Update
                    </Button>
                  )}

                  <Button
                    type="button"
                    className="btn btn-danger"
                    onClick={closeModal}
                    style={{ marginLeft: "20%" }}
                  >
                    <AiFillCloseCircle /> Close
                  </Button>
                </div>
              </>
            )}
          </div>
        </Form>
      </ReactModal>
    </div>
  );
}

export default Modal;
